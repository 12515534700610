import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionBox from "../components/section-box"
import SectionBox2 from "../components/section-box2"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"

//Ilustration inports
import ilustration2 from "../images/oblakoder-home-2.png"

//Styled Components Import

import SvgBg1 from "../components/styled/SvgBg"
import GridStyled1 from "../components/styled/Grid1"
import ImageBox1 from "../components/styled/ImageBox"

const AwsSecurityInTheCloud = () => (
  <Layout>
    <SEO title="AWS Security in the cloud" />
    <SvgBg1>
      <GridStyled1>
        <div>
          <ScrollAnimation animateIn="fadeIn" duration={1.8}>
            <h1>
              <span>AWS</span>
              <br /> Security in the Cloud
            </h1>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={150} duration={1.3}>
            <h3>Oblakoder</h3>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn" delay={250} duration={1.4}>
            <SectionBox>
              <p>
                <b>Security in the Cloud</b>
              </p>
            </SectionBox>
            <SectionBox2>
              <p>
                The security is the foundation of all our services. Our
                engineers with AWS Certified Security - Specialty certificate
                will secure your AWS account, create and manage users and
                groups, define polices and roles.
              </p>
            </SectionBox2>
            <SectionBox2>
              <p>
                We know how to protect your data and services. We are
                specialized in data classification and compliance and we know
                protection mechanisms on AWS. Your data will be encrypted at
                rest and transferred using secure Internet protocols and your
                services will be guarded by the most sophisticated tools.
              </p>
            </SectionBox2>
            <SectionBox2>
              <p>
                Most of the security breaches can be automatically fixed. By
                providing you with the complete security report and our
                suggestion how to improve your security, you will be in position
                to decide what services and tools you want to implement in your
                cloud and automate or react on threats.
              </p>
            </SectionBox2>
          </ScrollAnimation>
        </div>
        <ImageBox1>
          <div className="png-img">
            <img src={ilustration2} alt="AWS Security in the cloud" />
          </div>
          <div className="res-img">
            <img src={ilustration2} alt="AWS Security in the cloud" />
          </div>
        </ImageBox1>
      </GridStyled1>
    </SvgBg1>
  </Layout>
)

export default AwsSecurityInTheCloud
